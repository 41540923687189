export default {

  signInWithGoogle: "Connectez-vous avec Google",
  signInWithApple: "Connectez-vous avec Apple",
  email: "E-mail",
  password: "Un mot de passe",
  signIn: "Entrée",
  register: "inscription",
  merchant: "marchand",
  user: "utilisateur",
  fname: "имя",
  lname: "фамилия",
  businessName: "Nom d'utilisateur",
  regNo: "H.P./T.Z",
  website: "adresse du site Web",
  location_url: "URL de localisation",
  emailAddress: "Адрес электронной почты",


  createPassword: "Придумайте пароль",
  confirmPassword: "Подтвердите пароль",
  phoneNumber: "Номер телефона",
  idNumber: "Numéro d'identification",
  idNumbermsg: "Vous devrez ajouter un numéro de carte à puce pour vérifier le profil. Vous devrez vous rendre dans votre banque postale locale et/ou commander une carte en ligne avec livraison gratuite à domicile et fournir vos informations d'inscription, y compris la preuve de la pièce d'identité gouvernementale que vous avez saisie dans la demande au moment de l'inscription. Après avoir reçu la vérification, nous vérifierons votre profil.",
  streetAddName: "nom de rue",
  streetNo: "Numéro de rue",
  houseNo: "Numéro de maison",
  city: "Ville",
  district: "Zone",
  from: "Depuis:",
  to: "À une heure :",
  addBusinessLogo: "Ajouter un logo",
  businessLogo: "Logo d'entreprise",
  businessHours: "Temps d'activité",


  validEmail: "please enter valid email.",
  validPassword: "please enter valid password.",

  signInAs: "Inscrivez-vous en tant que :",
  donHaveAccount: "je ne me suis pas inscrit",
  alreadyHaveAccount: "Vous avez déjà un compte?",
  haventReceivedOTP: "Vous n'avez pas reçu de code ?",
  registerAs: "Inscrivez-vous en ligne",
  verify: "identification",
  resend: "pour le début",
  next: "Следующий",
  back: "en arrière",


  personalInformation: "Informations personnelles",
  morePersonalInfo: "Parle de toi",
  moreInfoBusiness: "Parlez-nous de votre entreprise",
  businessInformation: "Informations d'affaires",
  contactDetails: "Contactez-nous",
  businessActivity: "activités commerciales",
  businessSector: "Domaine d'activité",
  establishmentYear: "année d'établissement",

  maritalStatus: "Statut actuel",
  numbersofChildren: "un certain nombre d'enfants",
  occupation: "statut",
  educationLevel: "éducation",

  VerifyPhoneNumber: "Vérification de l'adresse e-mail",
  enterOtp: "Entrez un code de vérification",
  otpMsg: "Nous vous avons envoyé un mot de passe à usage unique à votre adresse e-mail enregistrée.",


  languageSelector: 'Choisissez la langue',

  changePassword: "Changer le mot de passe",
  currentPassword: "Mot de passe actuel",
  newPassword: "nouveau mot de passe",
  chooseLanguage: "Choisissez la langue",
  languageSettings: "Langues",
  save: "en gardant",
  cancel: "annulation",
  removeAccount: "Supprimer le compte",
  ok: "D'ACCORD",
  yes: 'Да',
  no: 'Нет',
  permissions_for_record_audio: 'Разрешения на запись аудио',
  give_permission_to_your_device_to_record_audio: 'Разрешите вашему устройству записывать звук',
  okay: 'Хорошо',

  coupons: "coupons",
  publicFeeds: "Recueils de la Torah",
  feeds: "Profil privé",
  blogs: "Blog",
  search: "Recherche",
  couponsMsg: "Affiche tous les coupons",
  blogsMsg: "blogs",

  couponTitle: "Flat 20% Discount at McDonald's",
  couponDesc: "Lorem Ipsum Terms & Conditions will go here",
  couponCode: "MCD20",
  validaty: "validité:",
  couponExpDate: " 31 mai 2019",

  addBtn: "ajouter",
  updateBtn: "mise à jour",
  editBtn: "édition",
  viewBtn: "afficher",
  saveBtn: "Préserver",
  statsBtn: "statique",

  // feedsSearch:"",
  feedTitle: "Dream Creations Event",
  feedDesc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

  addComment: "Комментарий",

  blogTitle: "A Curious Fancy",
  blogDesc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  category: "категория",
  sports: "спорт",
  report: "отчет",
  reportMessage: "Вы уверены, что хотите сообщить об этом контенте?",
  readMore: "читать далее",
  readLess: "Читать меньше",
  recentComments: "Недавние комментарии",

  coupon_title: "Coupon Title",
  coupon_title_ab: "Coupon Title (Arabic)",
  coupon_title_he: "Coupon Title (Hebrew)",
  coupon_description: "Coupon Description",
  coupon_code: "код купона",
  discount_amount: "сумма скидки",
  discount_type: "Тип скидки",
  qrcode_url: "URL-адрес QR-кода",
  storelocation: "Выберите местоположение магазина",
  storecategory: "Выберите категорию",
  create_date: "Создать дату",
  expiry_date: "Дата истечения срока действия",
  term_condition: "Условия и положения",


  dabitcardTitle: "«Вы уже подали заявку как «умный житель Израиля». Пожалуйста, проверьте статус вашей заявки ниже».",
  dabitcardTitle1: "Нажмите «Применить сейчас», чтобы отправить заявку, или пропустите, чтобы продолжить.",
  addTerms: "Добавить условия",

  apply_filters: "Добавить фильтры",
  apply: "Применять",
  clear_all: "очистить все",
  categories: "категории",
  location: "Место",
  flat: "Зафиксированный",
  percentage: "процент",
  valid_all_stores: "Действует во всех магазинах",
  my_coupons: "мои купоны",

  thankyou: "Спасибо",
  thankyou_msg: "Мы получили Ваш запрос на регистрацию в качестве владельца бизнеса в приложении «Тошав Хахам Исраэль». Шамим сообщает вам, что вам разрешено публиковать сообщения, пока мы занимаемся рассмотрением и утверждением вашего запроса.",
  applyAgain: "Подать заявку еще раз",
  cancelApplication: "Отменить запрос",
  status: "положение дел",
  skip: "пропускать",
  applyNow: "Применить сейчас",
  activeCoupons: "Купоны активны",
  inactiveCoupons: "Неактивные купоны",
  couponsAddedBy: "Купоны добавлены пользователем",
  couponsUsedBy: "Купоны, используемые",
  totalShare: "Общий доступ",
  whatsapp: "WhatsApp",
  shareOnWhatsApp: "Поделиться в WhatsApp",
  shareOnEmail: "Поделиться по электронной почте",
  share: "делиться",
  accept: "Получать",
  clearAll: "очистить все",
  code: "код",
  OneTimePassword: "одноразовый пароль (OTP)",
  selectItem: "выберите предмет",
  review: "Обзор",
  reviewTitle: "как прошел твой опыт",
  submit: "Отправлять",
  chooseLibrary: "Выбирайте из библиотеки",
  image: "Изображение",
  video: "видео",
  loginwith: "Войти в систему с",
  or: "или",
  document: "документ",
  gallery: "галерея",
  audio: "Аудио",
  Typemessage: "Введите сообщение",

  media: "СМИ",
  shareMedia: "Делитесь фотографиями и видео",
  photos: "Фото",
  sharePhotos: "Обмениваться фотографиями",
  videos: "Видео",
  shareVideos: "Делитесь видео",
  shareAudios: "Поделиться аудио",
  file: "файл",
  shareFile: "обмениваться документами",
  selectFile: "Выберите файл",
  select: "выбирать",
  block: "блокировать",
  photo: "Изображение",

  logout: "Выйти",
  logout_message: "ты хочешь отключиться?",
  delete_chat: "Удалить сообщение",
  delete_chat_message: "Вы хотите удалить сообщение?",
  delete_account_name: "Удалить учетную запись",
  delete_account_message: "Вы хотите удалить учетную запись?",
  delete_post: "Удалить сообщение",
  delete_post_message: "Вы хотите удалить сообщение?",
  block_user: "Блокировать пользователя",
  block_user_message: "Вы хотите заблокировать пользователя?",
  un_block_user: "Разблокировать пользователя",
  un_block_user_message: "Вы хотите разблокировать пользователя?",
  disconnect_user: "Отключить пользователя",
  disconnect_user_message: "Вы хотите отключить пользователя?",
  connect_user: "Подключить пользователя",
  connect_user_message: "Вы хотите подключить пользователя?",

  message: "Сообщение",
  edit_profile: "Редактировать профиль",
  posts: "Сообщения",
  connects: "Соединяет",
  connect: "Соединять",
  connected: "Связанный",
  account_privacy: "Конфиденциальность аккаунта",
  public: "Общественный",
  private: "Частный",
  private_account: "Личный кабинет",
  blocked_users: "Заблокированные пользователи",
  privacy_msg1: "Когда ваша учетная запись общедоступна, ваш профиль и сообщения могут видеть все, как в Тошав Хахам, так и за его пределами, даже если у них нет учетной записи Тошав Хахам.",
  privacy_msg2: "Если ваша учетная запись является частной, только те пользователи Connections, которые вы одобрили, смогут видеть то, чем вы делитесь, включая ваши фотографии и видео, а также ваши списки Connections.",
  go_back: "Возвращаться",
  create_post: "Создать сообщение",
  post_upload_msg: "Нажмите, чтобы загрузить изображение и видео",
  post_error_msg: "Пожалуйста, загрузите изображение или видео для публикации",
  comments: "Комментарии",
  delete: "Удалить",
  confirm: "Подтверждать",
  under_maintenance: "Приложение в настоящее время находится на обслуживании.",
  postCreateMsg: "напишите подпись...",
  disconnect: "Отключить",
  unblock: "Разблокировать",
  shabBatTital: "активные наблюдатели Шаббат",
  shabBatDesc: "Я хотел бы, чтобы меня видели рекламодатели, соблюдающие Шаббат, и/или те, кто определяет свою учетную запись как соблюдающую Шаббат.",
  on: "ОН",
  off: "ВЫКЛЮЧЕННЫЙ",
  bio: "Био",
  user_name: "Имя пользователя",
  link: "Связь",
  views: "Просмотры",
  view: "вид",
  shabBat: "Суббота",
  accountIsPrivate: "Эта учетная запись является частной",
  locationNotFound: 'Местоположение не найдено',
  requested:'Запрошено',
  seemore:'Узнать больше',
  broadcastEnd:'Вещание было прекращено',
  cancelRequsted:'Отменить запрос',
  cancelRequsted_message:"Вы хотите отменить запрошенное?",
  gender:'Пол',
  yourlocation:  'Выберите свой город',
  profileComplete:'Полный профиль',
  profileComplete_msg:'Пожалуйста, заполните свой профиль.',
  isLive:"живой!",
  goToLive:"Перейти в прямой эфир",
  leaveRoom:"Покинуть комнату",
  live:"Жить",

  filters: "Filters",
  allConversion: "All Conversations",
  unread:"Unread",
  read:"Read",
  favorites:"Favorites",
  archive:"Archive",
  trash:"Trash",



  //web app text
  popularCategories: "Популярные категории",
  latestCoupon: "Последние купоны",
  latestBlogs: "Последние блоги",
  recentPost: "Недавние Посты",
  copyCode: "Копировать код",
  couponMsg: "Используйте этот код купона при оформлении заказа",
  loadMore: "Загрузи больше",
  seeAllBlogs: "Посмотреть все блоги",
  upto: "ВПЛОТЬ ДО",
  off: "ВЫКЛЮЧЕННЫЙ",
  downloadApp: "Загрузите наше приложение",
  allRightReserved: "Тошав Хахам Все права защищены @ 2023",
  loginRequire: "Вы должны быть зарегистрированы",
  type:"Тип",
  selectAudience:"Выберите аудиторию"
};