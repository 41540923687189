import * as ReactDOMClient from "react-dom/client";

import React from "react";

import App from "./App";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import "./index.css";
import { registerServiceWorker } from "./serviceWorker";
import { SocketProvider } from "./contexts/SocketProvider";
import { AppContextProvider } from "./contexts/AppProvider";
import { ChatMessageCountProvider } from "./contexts/chatCountProvider";

const container = document.getElementById("root");
const root = ReactDOMClient.createRoot(container);

registerServiceWorker();
root.render(
  <React.Fragment>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SocketProvider>
          <AppContextProvider>
            <ChatMessageCountProvider>
              <App />
            </ChatMessageCountProvider>
          </AppContextProvider>
        </SocketProvider>
      </PersistGate>
    </Provider>
  </React.Fragment>
);

// ReactDOM.render(
//   <React.Fragment>
//     <Provider store={store}>
//       <PersistGate loading={null} persistor={persistor}>
//         <App />
//       </PersistGate>
//     </Provider>
//   </React.Fragment>
//    ,
//   document.getElementById("root")
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
