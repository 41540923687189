const arrowDown = require("../assets/images/arrow-down.png");
const coupons = require("../assets/images/coupons.png");
const public_feeds = require("../assets/images/public_feeds.png");
const blogs = require("../assets/images/blogs.png");
const share = require("../assets/images/share.png");
const filter = require("../assets/images/filter.png");
const search = require("../assets/images/search.png");
const search_ = require("../assets/images/search_.png");
const like = require("../assets/images/like.png");
const dislike = require("../assets/images/dislike.png");
const like_white = require("../assets/images/like_white.png");
const emoji = require("../assets/images/emoji.png");
const send_message = require("../assets/images/send_message.png");
const mac_logo = require("../assets/images/mac_logo.png");
const video_image = require("../assets/images/video_image.png");
const menu = require("../assets/images/menu.png");
const arrow_back = require("../assets/images/arrow_back.png");
const close = require("../assets/images/close.png");
const check = require("../assets/images/check.png");
const drawCheck = require("../assets/images/draw-check.png");
const upload = require("../assets/images/upload.png");
const logo = require("../assets/images/logo.png");
const logo_ = require("../assets/images/logo_.png");
const calendar = require("../assets/images/calendar.png");
const deleteIcon = require("../assets/images/delete.png");
const eyeIcon = require("../assets/images/eye.png");
const eyeoffIcon = require("../assets/images/eye_off.png");
const loginBg = require("../assets/images/login_bg.jpg");
const logoLight = require("../assets/images/logo_light.png");
const iconLocation = require("../assets/images/icon_location.png");
const iconWeb = require("../assets/images/icon_web.png");
const icon_user = require("../assets/images/icon_user.png");
const icon_logo_bg = require("../assets/images/icon_logo_bg.jpg");
const attach = require("../assets/images/attach.png");
const playButton = require("../assets/images/play_button.png");
const pdf = require("../assets/images/pdf.png");
const rating = require("../assets/images/rating.png");
const chat = require("../assets/images/chat.png");
const gallery = require("../assets/images/gallery.png");
const rightArrow = require("../assets/images/right-arrow.png");
const user = require("../assets/images/user.png");
const bell = require("../assets/images/bell.png");
const close_blue = require("../assets/images/close_blue.png");
const playStore = require("../assets/images/playStore.png");
const appStore = require("../assets/images/appStore.png");
const phoneCall = require("../assets/images/phone-call.png");

const mail = require("../assets/images/mail.png");
const RabiImage = require("../assets/images/rabi-image.jpeg");
const appleLogo = require("../assets/images/apple-logo.png");
const appleLogoWhite = require("../assets/images/apple_logo.png");
const googleLogo = require("../assets/images/google.png");
const heart = require("../assets/images/heart.png");
const heartFilled = require("../assets/images/heart-filled.png");
const comment = require("../assets/images/comment.png");
const share_dark = require("../assets/images/share_dark.png");

const iconDocs = require("../assets/images/docs.png");
const iconPlay = require("../assets/images/play.png");
const iconPause = require("../assets/images/pause.png");
const iconGalleryNew = require("../assets/images/gallery-new.png");
const iconHeadPhones = require("../assets/images/headphones.png");
const iconMic = require("../assets/images/mic.png");
const iconCamera = require("../assets/images/camera.png");
const iconPlus = require("../assets/images/plus.png");
const iconDefaultUser = require("../assets/images/default_user.png");
const iconDots = require("../assets/images/dots.png");
const iconDotsBlue = require("../assets/images/dots_blue.png");
const iconDocsNew = require("../assets/images/docs-new.png");
const iconVideoCamera = require("../assets/images/video-camera.png");
const iconVideoCameraOff = require("../assets/images/no-video.png");
const iconSwitchCamera = require("../assets/images/switch-camera.png");
const iconPicture = require("../assets/images/picture-icon.png");
const messenger = require("../assets/images/messenger.png");
const iconPlusSocial = require("../assets/images/plus-social.png");
const iconHome = require("../assets/images/home.png");
const iconSetting = require("../assets/images/setting.png");
const iconSettingDark = require("../assets/images/settings-dark.png");
const iconNext = require("../assets/images/next.png");
const iconRightArrow = require("../assets/images/right-arrow.png");
const iconBlock = require("../assets/images/block.png");
const iconLock = require("../assets/images/lock.png");
const iconLockDark = require("../assets/images/lock-dark.png");
const iconBlockDark = require("../assets/images/block-dark.png");
const iconDownload = require("../assets/images/download.png");
const iconHeart = require("../assets/images/heart.png");
const iconHeartFill = require("../assets/images/heart-fill.png");
const iconChatBubble = require("../assets/images/chat-bubble.png");
const iconVideo = require("../assets/images/icon-video.png");
const iconVolume = require("../assets/images/volume.png");
const iconMute = require("../assets/images/mute.png");
const iconCopy = require("../assets/images/copy.png");
const iconBell = require("../assets/images/bell.png");
const iconArrow = require("../assets/images/icond.png");
// const iconVerifiedAccount = require('../assets/images/verified-account.png');
const iconVerifiedAccount = require("../assets/images/shield.png");
const iconShaBat = require("../assets/images/shabat.png");
const iconMicOff = require("../assets/images/mic-off.png");
const iconTime = require("../assets/images/time.png");
const iconMap = require("../assets/images/map.png");
const iconHyperLink = require("../assets/images/hyperlink.png");
const iconVerify = require('../assets/images/verify.png');
const imgLanding = require('../assets/images/landing.png');
const imgStar = require('../assets/images/star.png');
const imgRecording = require('../assets/images/recording.png');
const imgStop = require('../assets/images/stop.png');
const imgShare = require('../assets/images/share.png');
const imgShareStop = require('../assets/images/shareStop.png');

export {
  arrowDown,
  coupons,
  public_feeds,
  blogs,
  share,
  search,
  search_,
  filter,
  like,
  dislike,
  like_white,
  emoji,
  send_message,
  mac_logo,
  video_image,
  menu,
  arrow_back,
  close,
  check,
  drawCheck,
  upload,
  logo,
  logo_,
  calendar,
  deleteIcon,
  eyeIcon,
  eyeoffIcon,
  loginBg,
  logoLight,
  iconLocation,
  iconWeb,
  icon_user,
  icon_logo_bg,
  attach,
  playButton,
  pdf,
  rating,
  chat,
  gallery,
  rightArrow,
  user,
  bell,
  close_blue,
  playStore,
  appStore,
  phoneCall,
  mail,
  RabiImage,
  appleLogo,
  appleLogoWhite,
  googleLogo,
  iconDefaultUser,
  heart,
  heartFilled,
  comment,
  share_dark,
  iconDocs,
  iconPlay,
  iconPause,
  iconGalleryNew,
  iconHeadPhones,
  iconMic,
  iconCamera,
  iconPlus,
  iconDots,
  iconDotsBlue,
  iconDocsNew,
  iconVideoCamera,
  iconVideoCameraOff,
  iconSwitchCamera,
  iconPicture,
  messenger,
  iconPlusSocial,
  iconHome,
  iconSetting,
  iconSettingDark,
  iconNext,
  iconRightArrow,
  iconBlock,
  iconLock,
  iconBlockDark,
  iconLockDark,
  iconDownload,
  iconHeart,
  iconHeartFill,
  iconChatBubble,
  iconVideo,
  iconVolume,
  iconMute,
  iconCopy,
  iconBell,
  iconArrow,
  iconVerifiedAccount,
  iconShaBat,
  iconMicOff,
  iconTime,
  iconMap,
  iconHyperLink,
  iconVerify,
  imgLanding,
  imgStar,
  imgRecording,
  imgStop,
  imgShare,
  imgShareStop
};
