export default {
    home: 'Maison',
    login: 'connexion',
    register: "enregistrer",
    settings: 'Définitions',
    signin: 'Connecter',
    otpView: 'OtpView',
    myCoupons: 'mes coupons',
    addCoupon: 'Ajouter un coupon',
    editCoupon: 'Modifier un coupon',
    myCouponsClient: 'mes coupons',
    backCouponsList: 'Retour à la liste des coupons',
    backPublicFeed: 'Retour à Aspi Torah',
    couponStatistics: 'Statistiques des coupons',
    backBlogs: 'Retour aux blogs',
    myProfile:"mon profil",
    myProfileMerchant:"mon profil",
    smartCitizenDebitCard: "Toshav Haham",
    aboutSmartCitizenApp:"à propos de nous",
    notification:"Alertes",
    help:"aide",
    termsncondition:"Termes et conditions",
    logout:"Se déconnecter",
    forgotPassword: 'Mot de passe oublié',
    resetPassword: 'réinitialiser le mot de passe',
    generateOtp: 'Générer du code',
    couponReview: 'Commentaires',
    messages:"E-mail",
    accountPrivacy:"Confidentialité du compte",
    settingsPrivacy:"Paramètres et confidentialité",
    postDetail:"Détail du message",
    socialHome:"Flux public",
    postCreate:"Créer un message",
    postProfile:"Profil social",
    chat:"Chat",
    blockedUsers:"Utilisateurs bloqués",
    connectedUsers:"Utilisateurs connectés",
    likes:"Aime",



      //web app text
  guestuser: 'Utilisateur invité',
  social: "Social",
  };