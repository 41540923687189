import React from "react";
import "./header.css";

// import { FaAngleDown } from "react-icons/fa";
// import { AiTwotoneSetting } from "react-icons/ai";
//import { AiOutlineLogout } from "react-icons/ai";

import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";

import axios from "axios";
import { bell, iconDefaultUser, iconDots, logo, logo_, user } from "../../constants/images";
import { actions } from "../../redux/actions";
import { BASPATH } from "../../config/appConfig";
import { useTranslation } from "react-i18next";
import { Alert } from "bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { logoutApi, singinActions } from "../../redux/actions/loginActions";
import {
  ShowErrorToast,
  getPlatform,
  isRTL,
  openMobileLink
} from "../../utils/common";
import { Toast } from "react-bootstrap";
//import { onMessageListener } from '../../firebase';
import { getToken } from "firebase/messaging";
import { UseAppContext } from "../../contexts/AppProvider";
import { openInNewTab } from "../../constants/constant";

export default function HeaderMain(props) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [toggleon, toggleoff] = useState(false);
  const [toggleMainMenu, setToggleMainMenu] = useState(false);
  const [open, close] = useState(false);
  let navigate = useNavigate();

  const [show, setShow] = useState(false);

  const authUser = useSelector(({ auth }) => auth.data);
  const userProfile = useSelector(({ user }) => user.profile);

  //assigning location variable
  const location = useLocation();

  const { width, height, isMobile } = UseAppContext();
  // const isMobile = width <= 768;
  // const isMobile = false;

  //destructuring pathname from location
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  console.log(`pathname ??????`, splitLocation);

  // Function to clear complete cache data
  const clearCacheData = () => {
    caches.keys().then(names => {
      names.forEach(name => {
        caches.delete(name);
      });
    });
    //  alert('Complete Cache Cleared')
  };

  const headerArrayGuastUser = [
    { label: t("common:coupons"), value: "1", path: "coupons" },
    //   { label: t("navigate:myCoupons"), value: '1', path: "myCoupons" },
    { label: t("common:publicFeeds"), value: "2", path: "torah-collections" },
    { label: t("common:blogs"), value: "3", path: "blog" },
    { label: t("navigate:aboutSmartCitizenApp"), value: "4", path: "about" }
  ];

  const headerArrayUser = [
    { label: t("common:coupons"), value: "1", path: "coupons" },
    { label: t("navigate:myCoupons"), value: "1", path: "myCoupons" },
    { label: t("common:publicFeeds"), value: "2", path: "torah-collections" },
    { label: t("common:blogs"), value: "3", path: "blog" },
    //  { label: t("common:social"), value: "4", path: "social" },
    { label: t("navigate:aboutSmartCitizenApp"), value: "5", path: "about" },
    {
      label: t("common:live"),
      value: "6",
      path: `user-broadcaster/${authUser.user_id}`
    },
    { label: t("navigate:socialHome"), value: "5", path: "social" },
    { label: t("navigate:messages"), value: "5", path: "chat" },
  ];

  const headerArrayMerchant = [
    { label: t("common:coupons"), value: "1", path: "coupons" },
    { label: t("common:publicFeeds"), value: "2", path: "torah-collections" },
    { label: t("common:blogs"), value: "3", path: "blog" },
    // { label: t("common:social"), value: "4", path: "social" },
    { label: t("navigate:aboutSmartCitizenApp"), value: "5", path: "about" },
    {  label: t("common:live"), value: "6", path: `user-broadcaster/${authUser.user_id}` },
    { label: t("navigate:socialHome"), value: "5", path: "social" },
    { label: t("navigate:messages"), value: "5", path: "chat" },
  ];

  const headerArray = !authUser.token
    ? headerArrayGuastUser
    : authUser.user_status === 1
      ? headerArrayMerchant
      : headerArrayUser;


  const onLogout = () => {
    const options = {
      title: t("common:logout"),
      message: t("common:logout_message"),
      buttons: [
        {
          label: t("common:ok"),
          onClick: () => {
            //  alert('Click Yes')
            setTimeout(() => {
              Logout();
            }, 150);
          }
        },
        {
          label: t("common:cancel"),
          onClick: () => {
            //alert('Click No')
          }
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      willUnmount: () => { },
      afterClose: () => { },
      onClickOutside: () => { },
      onKeypress: () => { },
      onKeypressEscape: () => { },
      overlayClassName: "overlay-custom-class-name"
    };

    confirmAlert(options);
  };

  const Logout = () => {
    dispatch(logoutApi(authUser.token))
      .then(res => {
        localStorage.removeItem("authUser");
        // localStorage.removeItem(config.authTokenKey);
        console.log("delete axios.defaults.headers");
        delete axios.defaults.headers.common["Authorization"];
        console.log("clearCacheData");
        // clearCacheData();
        console.log("dispatch LOGOUT");
        dispatch({
          type: actions.login.LOGOUT
        });
        navigate(BASPATH + "auth/login");
      }, 150)
      .catch(error => {
        ShowErrorToast(error);
      });
  };

  let toggle2 = () => {
    // console.log("toggle2");
    //if (!isMobile)
    toggleoff(!toggleon);
  };
  let toggle3 = () => {
    // console.log("here   ");
    // fetchToken(setTokenFound);
  };

  const [notification, setNotification] = useState({ title: "", body: "" });
  const [isTokenFound, setTokenFound] = useState(false);

  // console.log("getToken", getToken);

  //  getToken(setTokenFound);

  // onMessageListener().then(payload => {
  //   setNotification({ title: payload.notification.title, body: payload.notification.body })
  //   setShow(true);
  //   console.log(
  //     'Notification caused app to open from quit state:',
  //     payload.notification,
  //   );
  //   onShowNotificationClicked()
  //   console.log("onMessageListener +++",payload);
  // }).catch(err => console.log('failed: ', err));

  // const onShowNotificationClicked = () => {
  //   setNotification({ title: "Notification", body: "This is a test notification" })
  //   setShow(true);
  // }

  //console.log('isTokenFound', isTokenFound)

  const openWindow = "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=0,height=0,left=-1000,top=-1000"

  const RenderLive = (item, index) => {
    console.log('item.path', item.path)
    return (<li className={`${splitLocation[1] === item["path"] ? "active" : ""}`} key={index}
    style={{display:'inline-flex', alignItems:'center'}}
    >
      <div className={i18n.language == "en"? "pb-0": ""} onClick={() =>{window.open(BASPATH + item.path, "test", openWindow) }}>
        <label
          className={`${splitLocation[1] === item["path"] ? "active" : ""
            } ${i18n.language == 'en'? "font15":"font15" } pointer`}
        >
          {item.label}{" "}
        </label>
      </div>
    </li>)
  }


  const RenderAll = (item, index) => {
    return (<li
      className={`${splitLocation[1] === item["path"] ? "active" : ""
        } `}
      key={index}
    >
      <NavLink className="navbar" target={item.target} to={BASPATH + item.path}>
        <label
          className={`${splitLocation[1] === item["path"] ? "active" : ""
            } ${i18n.language == 'en'? "font15":"font15" } pointer`}
        >
          {item.label}{" "}
        </label>
      </NavLink>
    </li>)
  }


  console.log("getPlatform", splitLocation[1] === "smart-card");
  return (
    <div className="top-header-wrapper">
    <div className="container p-3">
      <div className="header">
        <div className="d-flex align-items-center">
          <img
            className="img-logo pointer"
            onClick={() => {
              if (isMobile) {
                openMobileLink();
                return;
              }
              navigate(BASPATH);
            }}
            src={logo_}
            style={{
              marginLeft: 5,
              justifySelf: "center"
            }}
            alt=""
          />
        </div>

        <div className="header_item ">
          {!isMobile ? (
            <ul
              className="header-list"
              onClick={() => {
                toggleoff(false);
              }}
            >
              {!isMobile &&
                headerArray.map((item, index) => {
                  return item.value == 6 ?
                    RenderLive(item, index)
                    : RenderAll(item, index)
                })}
            </ul>
          ) : null}

          {isMobile ? (
            <div className="dots-wrapper">
              {!authUser.token ? (
                <>
                  <span className={"btn"} onClick={openMobileLink}>
                    {t("common:signIn")}
                  </span>
                  <span>|</span>
                  <span className={"btn"} onClick={openMobileLink}>
                    {t("navigate:register")}
                  </span>
                </>
              ) : null}

              <img
                className="img dots pointer"
                src={iconDots}
                style={{}}
                onClick={() => {
                  toggleoff(false);
                  setToggleMainMenu(!toggleMainMenu);
                }}
                alt=""
              />

              {
                <ul
                  dir={isRTL(i18n.language) ? "rtl" : ""}
                  className={`tag_header_menu ${toggleMainMenu ? "active" : ""
                    }`}
                >
                  {headerArray.map((item, index) => {
                    //  console.log('item path', splitLocation[1] === item['path'])
                    return (
                      <li
                        className={`${splitLocation[1] === item["path"] ? "active" : ""
                          }`}
                        key={index}
                      >
                        <NavLink
                          onClick={() => {
                            setToggleMainMenu(!toggleMainMenu);
                            if (isMobile) {
                              openMobileLink();
                              return;
                            }
                          }}
                          className="navbar"
                          to={isMobile ? "" : BASPATH + item.path}
                        >
                          <label
                            className={`${splitLocation[1] === item["path"] ? "active" : ""
                              } pointer`}
                          >
                            {item.label}{" "}
                          </label>
                        </NavLink>
                      </li>
                    );
                  })}
                </ul>
              }
            </div>
          ) : null}


          <div className="end-wrapper">
          <div onClick={toggle3} className="d-flex align-items-center ms-2 me-2">
              {authUser.token ? (
                <img
                  className="img img-bell pointer"
                  src={bell}
                  style={{}}
                  onClick={() => {
                    if (isMobile) {
                      openMobileLink();
                      return;
                    }
                    navigate(BASPATH + "notification");
                  }}
                  alt=""
                />
              ) : null}

              <ul
                dir={isRTL(i18n.language) ? "rtl" : ""}
                className={`tag_name_menu ${toggleon ? "active" : ""}`}
              >
                {authUser.token ? (
                  <li className="menu-text">
                    <NavLink
                      onClick={() => {
                        toggle2();
                        if (isMobile) {
                          openMobileLink();
                          return;
                        }
                      }}
                      to={isMobile ? "" : BASPATH + "user-profile"}
                    >
                      <label
                        className={`${splitLocation[1] === "user-profile" ? "active" : ""
                          } pointer`}
                      >
                        {t("navigate:myProfile")}{" "}
                      </label>
                    </NavLink>
                  </li>
                ) : null}

                <li className="menu-text">
                  {
                    <NavLink
                      onClick={() => {
                        toggle2();
                        if (isMobile) {
                          openMobileLink();
                          return;
                        }
                      }}
                      to={isMobile ? "" : BASPATH + "settings"}
                    >
                      <label
                        className={`${splitLocation[1] === "settings" ? "active" : ""
                          } pointer`}
                      >
                        {t("navigate:settings")}{" "}
                      </label>
                    </NavLink>
                  }
                </li>
                <li className="menu-text">
                  {
                    <NavLink
                      onClick={() => {
                        toggle2();
                        if (isMobile) {
                          openMobileLink();
                          return;
                        }
                      }}
                      to={isMobile ? "" : BASPATH + "smart-card"}
                    >
                      <label
                        className={`${splitLocation[1] === "smart-card" ? "active" : ""
                          } pointer`}
                      >
                        {t("navigate:smartCitizenDebitCard")}{" "}
                      </label>
                    </NavLink>
                  }
                </li>
                <li className="menu-text">
                  {
                    <NavLink
                      onClick={() => {
                        toggle2();
                        if (isMobile) {
                          openMobileLink();
                          return;
                        }
                      }}
                      to={isMobile ? "" : BASPATH + "terms"}
                    >
                      <label
                        className={`${splitLocation[1] === "terms" ? "active" : ""
                          } pointer`}
                      >
                        {t("navigate:termsncondition")}{" "}
                      </label>
                    </NavLink>
                  }
                </li>
                
                {authUser.token ? (
                  <li className="menu-text " onClick={onLogout}>
                    <NavLink className="logout" onClick={toggle2} aria-disabled={'true'}>
                      <label
                         className="logout pointer"
                      >
                        {t("common:logout")}{" "}
                      </label>
                    </NavLink>
                  </li>
                ) : (
                  <li className="menu-text">
                    <NavLink className="logout"
                      onClick={() => {
                        toggle2();
                        if (isMobile) {
                          openMobileLink();
                          return;
                        }
                      }}
                      to={isMobile ? "" : BASPATH + "auth/login"}
                    >
                      <label
                         className="login-text" 
                      >
                        {" "}
                        {t("navigate:login")}{" "}
                      </label>
                    </NavLink>
                  </li>
                )}
              </ul>

              <Toast
                onClose={() => setShow(false)}
                show={show}
                delay={3000}
                autohide
                animation
                style={{
                  position: "absolute",
                  top: 20,
                  right: 20,
                  minWidth: 200
                }}
              >
                <Toast.Header>
                  {/* <img
                  src="holder.js/20x20?text=%20"
                  className="rounded mr-2"
                  alt=""
                /> */}
                  <strong className="mr-auto notification-text">
                    Notification
                  </strong>
                  <small>12 mins ago</small>
                </Toast.Header>
                <Toast.Body>
                  There are some new updates that you might love!
                </Toast.Body>
              </Toast>
            </div>
            <div
              onClick={() => {
                setToggleMainMenu(false);
                toggle2();
              }}
              className="d-flex justify-center align-items-center header-user"
            >
              <img
                className="default-img rounded-img img-s-38 pointer"
                src={
                  userProfile
                    ? authUser.user_status === 0
                      ? userProfile.profile_pic
                        ? userProfile.profile_pic
                        : iconDefaultUser
                      : userProfile.business_logo
                        ? userProfile.business_logo
                        : iconDefaultUser
                    : iconDefaultUser
                }
                style={{
                  justifyContent: "center"
                }}
                alt=""
              />
              
              <div className="user-wraper">
                <label>
                  {userProfile
                    ? authUser.user_status === 0
                      ? userProfile.first_name + " " + userProfile.last_name
                      : userProfile.business_name
                    : authUser.user_status === 0
                      ? authUser.first_name + " " + authUser.last_name
                      : authUser.business_name}
                </label>
                <span>
                  {!authUser.token
                    ? t("navigate:guestuser")
                    : authUser.user_status === 0
                      ? t("common:user")
                      : t("common:merchant")}
                </span>
              </div>
            </div>

          
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}
