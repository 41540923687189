import React, { useContext, useState } from "react";

const ChatCountContext = React.createContext();

export function UseChatCount() {
  return useContext(ChatCountContext);
}

export function ChatMessageCountProvider({ children }) {

  const [chatMessageCount, setChatMessageCount] = useState(0);
  const [isRefresh, setIsRefresh] = useState(0);
  const [selectedChatUser, setSelectedChatUser] = useState(0);

  return (
    <ChatCountContext.Provider value={{ 
      chatMessageCount, setChatMessageCount,
      isRefresh, setIsRefresh,
      selectedChatUser, setSelectedChatUser
     }} >
      {children}
    </ChatCountContext.Provider>
  );
}
