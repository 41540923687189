

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import PublicFeedList from './public-feed-list/publicFeedList';

import "./public-feed.css"

import InnerHeader from '../../../components/Layout/InnerHeader';
import HeadTags from '../../../components/Layout/Seo';
import Filters from '../../../components/Layout/Filters/filters';

const PublicFeed = () => {
    const { t, } = useTranslation();

    const [searchQuery, setSearchQuery] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSearchQuery(value);
    };

    return (
        <div className='container public-feed' >
            <HeadTags title='Israel 18' />

            <InnerHeader title={t('common:publicFeeds')}
                showSearch={false} searchQuery={searchQuery}
                handleInputChange={handleInputChange}
                placeholder={t("common:search") + " " + t("common:publicFeeds")}
            />
            <div className="row">
                <div className="col-lg-3 col-md-4">
                    <Filters
                        isCouponScreen={false}
                        couponCategory={[]}
                        blogsCategoryList={[]}
                        onBlogCallBack={() => { }}
                        searchQuery={searchQuery}
                        handleInputChange={handleInputChange}
                        searchPlaceHolder={t("common:search") + " " + t("common:publicFeeds")}
                        showPublicFeeds={true}
                    />

                </div>
                <div className="col-lg-9 col-md-8">
                    <PublicFeedList searchQuery={searchQuery} />
                </div>
            </div>
        </div>
    )
}

export default PublicFeed