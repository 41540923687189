import React from "react";
import "./footer.css";
import { appStore, mail, phoneCall, playStore } from "../../constants/images";
import { openInNewTab } from "../../constants/constant";
import { useTranslation } from "react-i18next";
import { UseAppContext } from "../../contexts/AppProvider";

const Footer = () => {
  const { t, i18n } = useTranslation();

  const { width, height, isMobile } = UseAppContext();
  // const isMobile = width <= 768;
  // const isMobile = false;

  const containerClass = isMobile
    ? "container pt-4 pb-4 justify-content-between"
    : "container d-flex pt-4 pb-4 justify-content-between";
  const innerClass = isMobile
    ? "d-flex mt-2 align-items-center"
    : "d-flex mt-0";


    const imgClass =
    isMobile  ? (i18n.language === "he" || i18n.language === "ar")
    ? "ms-4 me-0"
    : "ms-0 me-4":  (i18n.language === "he" || i18n.language === "ar") ? "ms-4 me-0"
      : "ms-0 me-4";

  const imgClass0 =
    isMobile && (i18n.language === "he" || i18n.language === "ar")
      ? "ms-4 me-0"
      : "ms-0 me-4";

  return (
    <footer className="">
      <div className="container footer-top pt-4 pb-4 text-center">
        <h2 className={isMobile ? "h4" : "h2"}>{t("common:downloadApp")}</h2>
        {/* <p className='p footer-desc mt-2'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.
                </p> */}

        <div className="text-center mt-5">
          <img
            className="ms-4 me-4 pointer"
            src={playStore}
            alt="Logo"
            width="55px"
            onClick={() =>
              openInNewTab(
                "https://play.google.com/store/apps/details?id=com.toshavhaham"
              )
            }
          />
          <img
            className="ms-4 me-4 pointer"
            src={appStore}
            alt="Logo"
            width="55px"
            onClick={() =>
              openInNewTab(
                "https://apps.apple.com/in/app/%D7%AA%D7%95%D7%A9%D7%91-%D7%97%D7%9B%D7%9D-%D7%99%D7%A9%D7%A8%D7%90%D7%9C/id1658696488"
              )
            }
          />
        </div>
      </div>

      <div className=" footer-bottom ">
        <div className={containerClass}>
          <div className="me-1 ms-1">
            <p className="p">{t("common:allRightReserved")}</p>
          </div>
          <div className={`${innerClass} me-1 ms-1 foo-mail`}>
            <img className={imgClass} src={mail} alt="Logo" width="26px" />
            <p
              className="p pointer"
              onClick={() =>
                (window.location = "mailto:support@toshavhaham.co.il")
              }
            >
              support@toshavhaham.co.il
            </p>
            {/* <img className='ms-4 me-4' src={phoneCall} alt="Logo" width='26px' />
                        <p className='p pointer' onClick={()=>  window.location = 'mailto: +91 7200000000'}>
                            +91 7200000000
                        </p> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
